@import './variables/layout/layout_light';

@import './mixin/mixin';

.layout-dashboard {
    .chart{
        overflow: auto;
    }
 
     .mobile-teams {
         display: none;
     }
 }
 @media (max-width: 1200px) {
     .layout-dashboard {
         .desktop-teams {
             display: none;
         }
     
         .mobile-teams {
             display: block;
             .team {
                 height: 100%;
                 flex-direction: column;
                 @include flex-justify-start();
                 @include flex-align-start();
                 .peoples {
                     margin: 12px -8px;
                 }
             }
         }
     }
 }

 .layout-dashboard {
    .orders {
        h4 {
            margin-bottom: 20px;
        }

        .p-button {
            margin-top: -20px;
        }

        .order-tabs {
            margin-bottom: 1rem;

            .order-tab {
                padding: 1rem 2rem 2rem 1rem;
                position: relative;
                transition: box-shadow 0.2s;

                &:hover {
                    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);
                    cursor: pointer;
                }

                i {
                    font-size: 1rem;
                    vertical-align: middle;
                }

                .order-label {
                    margin-left: 0.25rem;
                    vertical-align: middle;
                }

                .stat-detail-icon {
                    position: absolute;
                    right: 1rem;
                    top: 2.25rem;
                    height: 1rem;
                    width: 1rem;
                }

                img {
                    position: absolute;
                    bottom: 0;
                    left: 5%;
                    width: 90%;
                }
            }
        }
    }

    .overview-chart {
        overflow: auto;
    }

    .dashbard-demo-dropdown {
        min-width: 8rem;
        margin-left: auto;
    }

    .product-badge {
        border-radius: 2px;
        padding: 0.25em 0.5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.3px;

        &.status-instock {
            background: #c8e6c9;
            color: #256029;
        }

        &.status-outofstock {
            background: #ffcdd2;
            color: #c63737;
        }

        &.status-lowstock {
            background: #feedaf;
            color: #8a5340;
        }
    }
}

.notification {
    padding: 30px 24px;
    background-color:$contentShade100;
    @include border-radius(20px);

    > h6 {
        margin: 0;
        color: $textShade200;

        > a, > button {
            margin-left: 10px;
            font-weight: 600;
            i {
                @include rotate(45deg);
            }
        }
    }
}

.overview-box {
    @include flex();
    @include flex-justify-between();
    padding-top: 24px;
    height: 100%;
    min-width: 200px;

    .overview-info {
        > h6 {
            margin: 0 0 2px;
        }
        > h1 {
            margin: 0;
        }
    }

    > i {
        font-size: 24px
    }

    &.white {
        background: #FFFFFF ;
        color:rgba(41, 50, 65, 0.8);
    }

    &.blue {
        background: #69B7FF ;
        color: #FFFFFF;
    }

    &.gray {
        background: rgba(41, 50, 65, 0.4);
        color: #FFFFFF;
    }

    &.darkgray {
        background: rgba(41, 50, 65, 0.8);
        color: #FFFFFF;
    }

    &.orange {
        background: linear-gradient(90deg, #FFB340 0%, #FFA740 100%);
        color: #FFFFFF;
    }
}

.card {
    background: $contentShade100;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0px 10px 40px rgba(41, 50, 65, 0.06);
    @include border-radius($borderRadius);
    margin-bottom: 2rem;

    &:last-child {
        margin-bottom: 0;
    }

    .card-header {
        @include flex();
        @include flex-justify-between();
        padding-bottom: 16px;

        h6 {
            margin-bottom: 2px;
        }

        .subtitle {
            font-weight: 600;
            color: $textShade300;
        }
    }

    .card-subtitle {
        color: $textShade300;
        font-weight: 600;
        margin: -1rem 0 1rem 0;
    }

    &.no-gutter {
        margin-bottom: 0;
    }

}

.timeline {
    padding-right: 4px;
    > ul {
        padding: 0;
        margin: 0;
        list-style: none;
        max-height: 372px;
        overflow: auto;
        margin-bottom: 1em;

        > li {
            @include flex();
            margin-bottom: 16px;

            > i {
                font-size:8px;
                margin-right: 10px;
                margin-top: 4px;
            }

            .event-content {
                span {
                    display: block;
                    margin-bottom: 4px;
                    font-weight: 600;
                    font-size:12px;
                    color: $textShade300;

                    &.event-title {
                        color: $textShade100;
                    }

                    &.time {
                        font-size:10px;
                        font-weight: 400;
                        color: $textShade300;
                    }
                }
            }

            &.blue {
                > i {
                    color: #297FFF;
                }
            }
            &.green {
                > i {
                    color: #34B56F;
                }
            }
            &.orange {
                > i {
                    color: #FFA928;
                }
            }
        }
    }
}


.device-status {
    .content {
        color: $textShade300;
        line-height: 1.4;
        margin-bottom:20px;
    }
    .progress {
        @include flex();
        @include flex-align-center();
        padding: 10px 0;
        color: $textShade300;

        > span {
            min-width: 40px;
        }

        .progressBar {
            width: 100%;
            margin: 0 12px;
        }

        .p-progressbar {
            background:rgba(#297FFF,.2);
            background:var(--primary-lighter-color);

            .p-progressbar-value {
                background:rgba(#297FFF,.2);
                background:var(--primary-color);
                opacity: 0.8;
                border-radius: $borderRadius;
            }
        }
        &.active {
            .p-progressbar {
                background:rgba(#297FFF,.2);
                background:var(--primary-lighter-color);

                .p-progressbar-value {
                    background:linear-gradient(270deg, #42BBFF 0%, #6129FF 100%);
                    background:linear-gradient(270deg, var(--primary-lighter-color) 0%, var(--primary-color) 100%);
                    opacity: 0.8;
                }
            }
        }
    }

    .device {
        margin-bottom: 16px;
        span {
            color: $primaryColor;
            color: var(--primary-color);
            font-size: 14px;
            font-weight: 600;

            > span {
                font-size: 8px;
                font-weight: normal;
            }

            &.status {
                font-size: 12px;
                color: $textShade300;
                margin-top: 4px;
                display: block;
            }
        }
    }

}

.team {
    @include flex();
    @include flex-align-center();
    @include flex-justify-between();

    .card-header {
        padding: 0;
        min-width: 70px;
    }

    .peoples {
        @include flex();
        @include flex-align-center();
        flex-wrap: wrap;

        > img {
            @include border-radius(10px);
            margin: 8px 8px;
            width: 32px;
            height: 32px;
        }

        .no-picture {
            cursor: pointer;
            @include flex-center();
            @include border-radius(10px);
            margin: 8px 8px;
            width: 32px;
            height: 32px;
            background: rgba(41, 50, 65, 0.1);
            color: $textShade200;
            font-size: 12px;
            @include transition(background $transitionDuration);

            &:hover {
                background: rgba(41, 50, 65, 0.2);
            }
        }
    }
}

.operations {
    overflow: auto;
    .insights {
        padding: 16px 15px;
        background-color: rgba(41, 127, 255, .04);
        @include border-radius(12px);
        margin: 12px 0 16px;

        .insight-header {
            @include flex();
            @include flex-align-center();
            margin-bottom: 5px;

            h6 {
                margin: 0 6px;
            }
        }

        > ul {
            list-style: none;
            padding: 0;
            margin: 0;

            > li {
                margin: 8px 0;
                @include flex();
                @include flex-align-center();
                @include flex-justify-between();
                color:$textShade300;

                span {
                    font-weight: 600;

                    > span {
                        font-size: 8px;
                        line-height: 10px;
                        font-weight: normal;
                    }
                }
            }
        }
    }

    > button {
        width: 100%;
    }
}

.map {
    padding:0;

    > img {
        width: 100%;
        height:auto;
        border-radius: 24px 24px 12px 12px;
    }
    .map-content {
        padding:50px 20px 28px;
        h6 {
            margin:0 0 16px;
        }
        .city {
            margin-bottom: 16px;
            span {
                color: $primaryColor;
                color: var(--primary-color);
                font-size: 14px;
                font-weight: 600;

                > span {
                    font-size: 8px;
                    font-weight: normal;
                }

                &.status {
                    font-size: 12px;
                    color: $textShade300;
                    margin-top: 4px;
                    display: block;
                }
            }
        }
    }
}

.schedule {
    > p {
        color: $textShade300;
    }

    > ul {
        list-style: none;
        padding:0;
        margin: 0;

        > li {
            background: $contentShade400;
            border-radius: 8px;
            margin-bottom: 10px;
            padding: 5px 16px 12px;

            .schedule-header {
                @include flex();
                @include flex-justify-between();
                @include flex-align-center();

                h6 {
                    line-height: 24px;
                    margin: 0;
                }

                span {
                    color: $textShade300;
                    font-weight: 600;
                    font-size: 10px;
                    line-height: 14px;
                }

            }

            > span {
                margin-top:4px;
                color: $textShade300;
                display: block;
                font-size: 12px;
                line-height: 14px;
            }
        }
    }
}

.statistics {
    .statistic-item {
        .item-title {
            @include flex();
            @include flex-align-center();
            margin-bottom: 4px;

            span {
                display: block;
                margin-right: 12px;
            }
            h5{
                margin: 0;
                font-weight: 700;
            }
        }


        h6 {
            margin: 0;
            font-weight: 600;
            color:$textShade300;
        }
    }
}

.stocks {
    ul {
        list-style: none;
        padding:0;
        margin:0 ;

        > li {
            @include flex();
            @include flex-align-center();
            @include flex-justify-between();
            background-color: $contentShade200;
            padding: 0;
            margin: 0 0 12px;
            @include border-radius(6px);
            overflow: hidden;

            .stock-name {
                @include flex-center();
                background-color: $contentShade300;
                padding: 18px 10px;
                min-width: 70px;
                margin-right: 4px;

                h6 {
                    margin: 0;
                    color:$textShade200;
                    line-height: 17px;
                    font-weight: 600;
                }

            }

            > img {
                margin: 0 4px;
                height: 25px;
            }

            .stock-price {
                padding: 0 10px;
                color: #34B56F;
                margin: 0 4px;
                h6 {
                    line-height: 17px;
                    font-weight: 600;
                    display: inline-block;
                }
                i {
                    display: inline-block;
                }
            }

            .stock-status {
                margin-left: 4px;
                padding: 0 20px;
                span {
                    display: block;
                    font-weight: 600;
                    font-size: 10px;
                    line-height: 12px;
                    color: $textShade300;
                }
            }

            &.down {
                .stock-price {
                    color: #FF6E49;
                }
            }
            &.same {
                .stock-price {
                    color: #FFA928;
                }
            }
        }
    }

    > .p-button {
        width:100%;
        margin-top: 30px;
    }
}

// LANDINGPAGE
.marquee-wrapper:hover .marquee {
    animation-play-state: paused !important;
}

.marquee {
    white-space: nowrap;
    position: relative;
    transform: translate3d(0%, 0, 0);
    animation-name: marquee;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes marquee {
    0% {
        transform: translate3d(0%, 0, 0);
    }

    100% {
        transform: translate3d(-100%, 0, 0);
    }
}

.landing-wrapper {
    max-width: 1504px;
    margin-left: auto !important;
    margin-right: auto !important;
    background-color: #fff;
}

.landinglink{
    text-decoration: none;
    font-weight: bold;
}

.landing-banner {
    margin-bottom: 12rem;
}

@media screen and (max-width: 991px) {
    .landing-wrapper .landing-banner {
        height: auto !important;
        margin-bottom: 8rem;
    }
}

.visibility-hidden {
    visibility: hidden;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.fadein {
    animation: fadein 0.3s linear;
}
